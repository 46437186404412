@import "~/styles/theme.less";
@import "~antd/es/style/themes/default.less";

.iconfontWrapper {
  display: flex;
  flex-wrap: wrap;

  .iconfontItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: 10px;
    cursor: pointer;
    background: #d9d9d9;

    &.active {
      background: #40a9ff;
    }
  }
}
