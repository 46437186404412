@import "~/styles/theme.less";
.component {
  display: flex;
  width: 100%;
}

.item {
  flex: 1;
  min-width: 171px;
}
