@import "~/styles/theme.less";
.component {
  height: 100%;
  margin: 0 auto;
  width: 900px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  >div{
    width: 100%;
  }
  .head {
    padding: 10px 20px;
    border-bottom: 1px solid #e4e4e4;
  }
  .text {
    display: flex;
    height: 120px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e4e4;
    .Image {
      width: 40px;
      height: 40px;
      margin: 0 30px;
    }
    .div{
     flex: 1;
    }
    Button{
      float: right;
    }
  }
  .footer{
    padding: 10px 20px;
    height: 85px;
    background: #F2f2f2;
    color: #999999;
    h2{
      color: #999999;
    }
  }
}
