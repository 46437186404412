.sa {
  .widget-content {
    margin: -10px;
  }

  .widget-container {
    padding: 10px;
  }

  @media (max-width: 769px) {
    .widget-container[data-size='normal'] {
      min-height: 364px;
      height: auto;
    }

    .widget-container[data-size='normal'] .widget-content-container {
      min-height: 306px;
    }

    .widget-container[data-size='normal'] .widget-content-container [data-container='main'] {
      height: auto;
      min-height: 200px;
    }

    .widget-container[data-size='normal'] .widget-content-container [data-container='main'].only {
      min-height: 292px;
    }

    .widget-container[data-size='large'] {
      min-height: 390px;
      height: auto;
    }

    .widget-container[data-size='large'] .widget-content-container {
      min-height: 300px;
    }

    .widget-container[data-size='large'] .widget-content-container [data-container='main'] {
      height: auto;
      min-height: 300px;
    }

    .widget-container[data-size='mini'] {
      min-height: 182px;
      height: auto;
    }

    .widget-container[data-size='mini'] .widget-content-container {
      min-height: 162px;
    }

    .widget-container[data-size='mini'] .widget-content-container [data-container='main'] {
      height: auto;
      min-height: 88px;
    }

    .widget-container[data-module='utm'] {
      height: auto;
      min-height: 508px;
    }

    .widget-container[data-module='utm'] .widget-content-container {
      min-height: 414px;
    }

    .widget-container[data-module='utm'] .widget-content-container [data-container='main'] {
      height: auto;
    }
  }

  .widget-normal,
  [data-size='normal'] {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    height: 364px;
  }

  @media (min-width: 481px) {
    .widget-normal,
    [data-size='normal'] {
      float: left;
      width: 100%;
    }
  }

  @media (min-width: 769px) {
    .widget-normal,
    [data-size='normal'] {
      float: left;
      width: 100%;
    }
  }

  @media (min-width: 1200px) {
    .widget-normal,
    [data-size='normal'] {
      float: left;
      width: 50%;
    }
  }

  .widget-normal [data-container='main'],
  [data-size='normal'] [data-container='main'] {
    height: 200px;
  }

  .widget-normal [data-container='main'].only,
  [data-size='normal'] [data-container='main'].only {
    height: 275px;
  }

  .widget-normal [data-container='main'].only .no-data,
  .widget-normal [data-container='main'].only .no-support,
  [data-size='normal'] [data-container='main'].only .no-data,
  [data-size='normal'] [data-container='main'].only .no-support {
    margin: 0 auto;
    max-width: 80%;
    text-align: center;
    font-size: 16px;
  }

  .widget-x-small,
  [data-size='mini'] {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    height: 182px;
  }

  @media (min-width: 481px) {
    .widget-x-small,
    [data-size='mini'] {
      float: left;
      width: 50%;
    }
  }

  @media (min-width: 769px) {
    .widget-x-small,
    [data-size='mini'] {
      float: left;
      width: 50%;
    }
  }

  @media (min-width: 1200px) {
    .widget-x-small,
    [data-size='mini'] {
      float: left;
      width: 25%;
    }
  }

  .widget-x-small .widget-content-container .widget-title,
  [data-size='mini'] .widget-content-container .widget-title {
    max-width: calc(100% - 20px);
  }

  .widget-x-small [data-container='main'],
  [data-size='mini'] [data-container='main'] {
    height: 92px;
  }

  .widget-x-small [data-container='main'] .no-data,
  .widget-x-small [data-container='main'] .no-support,
  [data-size='mini'] [data-container='main'] .no-data,
  [data-size='mini'] [data-container='main'] .no-support {
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
  }

  .widget-x-small [data-container='main'] .to-report,
  [data-size='mini'] [data-container='main'] .to-report {
    height: 100%;
    padding: 20px 0;
  }

  .widget-x-small [data-container='main'] .to-report .report-link,
  .widget-x-small [data-container='main'] .to-report .no-support-tip,
  [data-size='mini'] [data-container='main'] .to-report .report-link,
  [data-size='mini'] [data-container='main'] .to-report .no-support-tip {
    display: inline-block;
  }

  .widget-small,
  [data-size='small'] {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    height: 182px;
  }

  @media (min-width: 481px) {
    .widget-small,
    [data-size='small'] {
      float: left;
      width: 100%;
    }
  }

  @media (min-width: 769px) {
    .widget-small,
    [data-size='small'] {
      float: left;
      width: 100%;
    }
  }

  @media (min-width: 1200px) {
    .widget-small,
    [data-size='small'] {
      float: left;
      width: 50%;
    }
  }

  .widget-small [data-container='main'],
  [data-size='small'] [data-container='main'] {
    height: 92px;
  }

  .widget-small [data-container='main'] .no-data,
  .widget-small [data-container='main'] .no-support,
  [data-size='small'] [data-container='main'] .no-data,
  [data-size='small'] [data-container='main'] .no-support {
    text-align: center;
    font-size: 14px;
  }

  .widget-large,
  [data-size='large'] {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    height: 390px;
  }

  @media (min-width: 481px) {
    .widget-large,
    [data-size='large'] {
      float: left;
      width: 100%;
    }
  }

  @media (min-width: 769px) {
    .widget-large,
    [data-size='large'] {
      float: left;
      width: 100%;
    }
  }

  @media (min-width: 1200px) {
    .widget-large,
    [data-size='large'] {
      float: left;
      width: 100%;
    }
  }
}
