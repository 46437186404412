@import "~/styles/theme.less";
@import "~antd/lib/style/themes/default.less";

.component {
  position: relative;
}

.uploadButton {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid @border-color-base;
}

.uploadButton_tip {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 100%;
  transform: translateX(8px);
}

.tip {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  font-size: 12px;
  color: @text-color-secondary;
  text-align: left;
  // left: 104px + 10px;
  // top: 104px - 40px;
}
