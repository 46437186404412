@import "~/styles/theme.less";
.brafEditor {
  position: relative;
}

:global {
  .w-e-text-container {
    background-color: #f6f6f6 !important;

    .placeholder {
      display: none !important;
    }
  }
}
